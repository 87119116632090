import React from "react";

export function AcademicVideo() {
  return (
    <div className="academic-video-container">
      <video
        className="academic-video"
        poster="https://bizy-assets.s3.amazonaws.com/assets/Bizy_Draft2_Final_Poster.png"
        controls
        muted
      >

        <source src="https://bizy-assets.s3.amazonaws.com/assets/Bizy_Draft2_Final.mp4" type="video/mp4" />
      </video>
    </div>
  )
}
